// EXEMPT
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "clutch/src/Button/Button.js";
import { LoadingSpinner } from "clutch/src/LoadingSpinner/LoadingSpinner.js";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP } from "@/__main__/constants.mjs";
import { setRoute } from "@/__main__/router.mjs";
import { CLIENT_TYPE } from "@/game-destiny2/utils/redirect-to-bungie.mjs";
import { AuthFailure } from "@/shared/AuthFailure.jsx";
import { AuthSuccess } from "@/shared/AuthSuccess.jsx";
import { useIsLoaded, useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const AUTH_TABS = {
  valid: {
    title: ["common:account.redirect.success.title", "Authorization Granted"],
    subtitle: [
      "common:authorized.success.subtitle",
      "Your account has been successfully authorized to access the app.",
    ],
  },
  invalid: {
    title: ["common:account.redirect.invalid.title", "Authentication Denied"],
    subtitle: ["destiny2:oauth.failure", "Please try again."],
  },
} as const;

const OAuthRedirect = () => {
  const { searchParams } = useRoute();
  const bungieId = searchParams.get("bungie_uid");
  const isFromApp = searchParams.get("client_type") === CLIENT_TYPE.app;

  if (!bungieId) return <AuthFailure {...AUTH_TABS.invalid} />;

  if (!IS_APP && isFromApp) return <OpenApp />;

  return <RedirectToProfile bungieId={bungieId} />;
};

function RedirectToProfile({ bungieId }: { bungieId: string }) {
  const isLoaded = useIsLoaded();
  const {
    settings: {
      loggedInAccounts: { destiny2: accounts },
    },
  } = useSnapshot(readState);

  useEffect(() => {
    if (isLoaded && accounts[bungieId])
      setRoute(`/destiny2/profile/${bungieId}`);
  }, [accounts, bungieId, isLoaded]);

  // TODO: show profile loading state?
  return <LoadingSpinner />;
}

function OpenApp() {
  const { t } = useTranslation();
  const { searchParams } = useRoute();

  return (
    <div className="w-full">
      <AuthSuccess {...AUTH_TABS.valid}>
        <p className="type-subtitle shade1 text-center">
          {t(
            "destiny2:auth.redirect.back.app",
            "We are now automatically redirecting you back to the app...",
          )}
        </p>
        <Button
          href={`blitz://open-url?link=/bungie-oauth-redirect${encodeURIComponent(
            `?${searchParams.toString()}`,
          )}`}
        >
          {t("destiny2:auth.open-app", "Open App")}
        </Button>
      </AuthSuccess>
    </div>
  );
}

OAuthRedirect.showAppNavigation = IS_APP;

export default OAuthRedirect;

export function meta() {
  return {
    title: ["", ""],
    description: ["", ""],
  };
}
